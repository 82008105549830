import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Daniel = () => {
	const { daniel: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Daniel Cheng`]}
				description="About Dr Daniel Cheng"
			/>
			<ProfilePage
				title="Dr Daniel (En Hsiang) Cheng – Interventional Endoscopist and Gastroenterologist"
				name="Dr Daniel Cheng"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="Therapeutic and general endoscopy, pancreaticobillary disease, Inflammatory bowel disease"
				education={[
					"Undergraduate: University of Queensland",
					"Post Graduate GI Training: Mater Hospital Brisbane, Queensland",
					"Post Graduate GI Training: Princess Alexandra Hospital, Brisbane, Queensland",
					"Fellowship (IBD): Mater Hospital Brisbane, Queensland",
					"Fellowship (Therapeutic Endoscopy): Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Fellowship (Therapeutic Endoscopy): Vancouver General Hospital and St Paul’s Hospital, Vancouver, Canada",
				]}
				description={[
					"Dr Daniel Cheng is a gastroenterologist who is dedicated to delivering holistic and patient focused care in general gastroenterology, therapeutic endoscopy and Inflammatory Bowel Disease.",
					"He has a subspecialty focus in therapeutic endoscopy, pancreaticobiliary disease and Inflammatory Bowel Disease. He has completed prestigious subspecialty fellowships both locally and Internationally and has substantial experience in managing complex gastroenterology patients. His area of endoscopic expertise include ERCP, EUS, surveillance and management of Barrett’s oesophagus, endoscopic management of large polyps, benign oesophageal stricture dilation and high quality surveillance colonoscopy for the prevention of colorectal cancer.",
					"Dr Daniel Cheng has public appointments at Sir Charles Gairdner Hospital and Osborne Park Hospital. He is a clinical lecturer of medicine at the University of Western Australia. He is multi-lingual and speaks fluent Mandarin, Cantonese, Hokkien and Malay.",
				]}
				honours={[
					"Bachelor of BioMedical Sciences 1st Class Honours.",
				]}
				background="Dr Daniel Cheng completed his Medical degree at the University of Queensland. He  completed his gastroenterology training in Brisbane including a fellowship year in Inflammatory Bowel Disease at the Mater Hospital Brisbane. He subsequently moved to Perth to pursue his passion in therapeutic endoscopy. He completed subspecialty training in Interventional Endoscopy at Sir Charles Gairdner Hospital and Vancouver General Hospital/St Paul’s Hospital (Vancouver, Canada)."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
					"Public appointment - Osborne Park Hospital (ASI Open Access Endoscopy)",
				]}
				memberships={[
					"American Society for Gastrointestinal Endoscopy (ASGE)",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"The Royal Australasian College of Physicians (RACP)",
					"Clinical Lecturer of Medicine – The University of Western Australia",
				]}
				personal={[
					"I enjoy hiking, playing guitar, reading and badminton.",
				]}
				publications={[
					{
						publicationTitle: "Factors influencing the need for endoscopic intervention in esophageal meat bolus impaction: A retrospective, multicentre study",
						authors: "Cheng D, Lee R, Lee A, Begun J, St John A.",
					},
					{
						publicationTitle: "Esophageal self-dilation with a Maloney dilator in patients with refractory benign esophageal stricture: Introduction into a tertiary center",
						authors: "Cheng D and Raftopoulos, S",
					},
					{
						publicationTitle: "The impact of maternal chronic hepatitis B in obstetric outcomes",
						authors: "Cheng EH, Witharana S and Haque M.",
					},
					{
						publicationTitle: "The impact of maternal chronic hepatitis B in neonatal outcomes",
						authors: "Cheng EH, Witharana S and Haque M.",
					},
					{
						publicationTitle: "Hepatitis B “e” antigen prevalence in the antenatal population: are we doing enough?",
						authors: "Cheng EH and Haque M.",
					},
					{
						publicationTitle: "Management of Food Bolus Impaction in the Emergency Department",
						authors: "Cheng EH and St John AT.",
					},
					{
						publicationTitle: "Cost-effectiveness of HCC surveillance in hepatitis B: A potential role for HbsAg quantification in determining surveillance intervals.",
						authors: "St John AT, Cheng EH and Haque M.",
					},
					{
						publicationTitle: "Hepatitis B vertical transmission risk assessment and follow up in the gastroenterology clinic – an Australian tertiary centre experience",
						authors: "Cheng EH, Gus G, Fereday E and Haque M.",
					},
					{
						publicationTitle: "Efficiency gains after the introduction of transient elastography to a hepatology outpatient service",
						authors: "Hendy P, Cheng EH, Wong J, Haque M",
					},
					{
						publicationTitle: "The utility and outcomes of endoscopic ultrasound guided fine needle aspiration of solid pancreatic masses and lymph nodes in a low volume referral centre",
						authors: "Wong J, Cheng EH, Wittmann J and Chang L",
					},
					{
						publicationTitle: "Early or delayed endoscopy for esophageal meat impaction: Get up at midnight or wait until morning?",
						authors: "Shahzad MA, St John A, Cheng D, Lee R, Li L, Lee A, Ghumman A, Fazal MW, Banh X, Baral S, Hourigan L, Holtmann G",
					},
					{
						publicationTitle: "Vedolizumab for ulcerative colitis: Real world outcomes from a multicenter observational cohort of Australia and Oxford.",
						authors: "Pulusu SS, Srinivasan A, Krishnaprasad K, Cheng D, Begun J, Keung C, Van Langenberg D, Thin L, Mogilevski T, De Cruz P, Radford-Smith G.",
					},
					{
						publicationTitle: "Curative embolization of an inferior mesenteric arteriovenous fistula causing ischaemic proctosigmoiditis.",
						authors: "Hendy P, Cheng EH, Livsey R, and Mortimore M.",
					},
					{
						publicationTitle: "Efficacy and Safety of Mycophenolate Mofetil in Patients With Autoimmune Hepatitis and Suboptimal outcomes After Standard Therapy.",
						authors: "Robert SK, Lim R, Strasser S, Nicoll A, Gazzola A, Mitchell J, Siow W, Khoo T, Hamarneh Z, Weltman M, Gow P, Janko N, Tse E, Mishra G, Cheng EH, Levy M, Cheng W, Sood S, Skoien R, Mitchell J, Zekry A, George J, MacQuillan G, Wigg Am, Stuart K, Sievert W, McCaughan G.",
					},
					{
						publicationTitle: "Anti-prolferative and mutagenic activites of aqueous and methanol extracts of leaves from Pereskia bleo (Kunth) DC (cactaceae)",
						authors: "Er HM, Cheng EH and Radhakrishnan A.",
					},
				]}
			/>
		</>
	);
};

export default Daniel;
